gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const menueDiv = document.getElementById('menue');

/**
 * Die beiden Konstanten nennen die per Scrolltrigger zu animierenden Elemente 
 * und ihren jeweiligen Vergrösserungsfaktor
 * @array {string, number, boolean} - Klassenname, Skalierungsfaktor, mit vorherigem gemeinsam skalieren
 */
const menueSetTabletDesktop = [
    ['link--angebot', 1.2, false],
    ['link--themen', 1.2, false],
    ['link--projekte', 1.2, false],
    ['link--profil', 1.2, false],
    ['link--kontakt', 1.2, true]
];
const menueSetSmartphone = [
    ['link--angebot', 1.6, false],
    ['link--themen', 1.6, false],
    ['link--projekte', 1.6, false],
    ['link--profil', 1.6, false],
    ['link--kontakt', 1.6, false]
];

/**
 * Setzt Scrolltrigger für alle Menüpunkte
 * @param  {Array} menueArray - Das beim Aufruf der Funktion gewählte Array mit den Scrolltrigger Einstellungen für die Menüpunkte
 */
function menueScrolltriggerAktivieren() {
    if (layoutAktuell == 'smartphone') {
        menueArray = menueSetSmartphone;
    } else {
        menueArray = menueSetTabletDesktop;
    }
    // Die menueAnzahlSkalierend wird genutzt, um die Scrolltrigger Animationen auf die Scrolllänge aufzuteilen
    // Gezählt werden nur die Punkte, die nicht synchron skalieren
    var menueAnzahlSkalierend = menueArray.filter(x => x[2] === false).length;
    var menueArrayLength0 = menueArray.length - 1;
    menueArray.forEach((menueElement, i) => {
        var alleNaechstenSynchron = true;
        // Boolean synchron von allen nächsten Menüpunkten lesen
        // Wenn alle true sind, wird der aktuelle Menüpunkt nicht verkleinert
        for (k=i; k < menueArrayLength0; k++) {
            if (menueArray[k + 1][2] === false) {
                alleNaechstenSynchron = false;
            }
        }
        // Erstes: Verkleinern durch From
        if (i == 0) {
            ScrollTriggerSetzen(menueArray[i][0], 'from', false, menueArray[i][1], false, true);
        // Letztes bloss Verkleinern und
        // Bloss verkleinern, wenn alle folgenden synchron sind
        } else if (i == menueArrayLength0 || alleNaechstenSynchron === true) {
            // Während des vorherige Element (vorheriger Scrolltrigger) kleiner wird, wird dieses grösser
            ScrollTriggerSetzen(menueArray[i][0], 'to', 'start', menueArray[i][1], menueArray[i][2], false);
        // Übrige: Erst vergrössern, dann verkleinern
        } else {
            // Während des vorherige Element (vorheriger Scrolltrigger) kleiner wird, wird dieses grösser
            ScrollTriggerSetzen(menueArray[i][0], 'to', 'start', menueArray[i][1], menueArray[i][2], false);
            // Erst wenn das Element grösser geworden ist (vorheriger Scrolltrigger), wird dieses kleiner
            ScrollTriggerSetzen(menueArray[i][0], 'to', 'end', (1 / menueArray[i][1]), false, false);
        }
        function ScrollTriggerSetzen(element, fromTo, startEnd, scale, synchronisierung, immediateRender) {
            element = document.getElementById('menue__' + menueArray[i][0]);
            // Der Zähler wird benötigt
            // – zum Erkennen des ersten Elements
            // – um alle Scrolltrigger zu killen, wenn das Menü geschlossen wird
            menueScrolltriggerZaehler += 1;
            // Je nach Elementnummer braucht es andere Optionen.
            // Sie werden dem GSAP-Aufruf hinzugefügt
            if (menueScrolltriggerZaehler == 1) {
                var scrollTriggerOptionen = {
                    scroller: menue,
                    trigger: menueDiv,
                    start: () => `top top`,
                    // Scrollbare Pixel geteilt durch Anzahl Menüpunkte minus 1
                    end: `${(menueDiv.scrollHeight - window.innerHeight) / (menueAnzahlSkalierend - 1)} top`,
                    scrub: true,
                    onUpdate: (self) => menueMenuepunktScrollAktivieren(element, fromTo, scale, self.progress),
                    // markers: { startColor: 'red', endColor: 'green' }
                }
            // Normalfall: Beginnen mit Verkleinern des vorherigen Scrolltrigger,
            // Start ist also der Beginn des vorherigen Scrolltriggers
            } else if (synchronisierung == false) {
                scrollTriggerOptionen = {
                    scroller: menue,
                    trigger: menueDiv,
                    // Start und Ende zu vergrössernder Elemente ist der Elementanfang (start)
                    // Start und Ende zu verkleinernder Elemente ist das Elementende (end)
                    start: self => self.previous()[startEnd],
                    // Scrollbare Pixel geteilt durch Anzahl Menüpunkte minus 1
                    end: self => self.previous()[startEnd] + ((menueDiv.scrollHeight - window.innerHeight) / (menueAnzahlSkalierend - 1)),
                    scrub: true,
                    onUpdate: (self) => menueMenuepunktScrollAktivieren(element, fromTo, scale, self.progress),
                    // markers: { startColor: 'blue', endColor: 'goldenrod' }
                }
            // Sonderfall synchron: Der Scrolltrigger beginnt mit dem Start des vorvorigen Scrolltriggers
            } else {
                scrollTriggerOptionen = {
                    scroller: menue,
                    trigger: menueDiv,
                    // Start und Ende zu vergrössernder Elemente ist der Elementanfang (start)
                    // Start und Ende zu verkleinernder Elemente ist das Elementende (end)
                    start: self => self.previous().previous()[startEnd],
                    // Scrollbare Pixel geteilt durch Anzahl Menüpunkte minus 1
                    end: self => self.previous().previous()[startEnd] + ((menueDiv.scrollHeight - window.innerHeight) / (menueAnzahlSkalierend - 1)),
                    scrub: true,
                    onUpdate: (self) => menueMenuepunktScrollAktivieren(element, fromTo, scale, self.progress),
                    // markers: { startColor: 'blue', endColor: 'goldenrod' }
                }
            }
            gsap[fromTo](element, {
                id: 'menueScrolltrigger--' + menueScrolltriggerZaehler,
                fontSize: '*=' + scale,
                paddingTop: '*=' + scale,
                paddingRight: '*=' + scale,
                paddingBottom: '*=' + scale,
                paddingLeft: '*=' + scale,
                immediateRender: immediateRender,
                scrollTrigger: scrollTriggerOptionen
            });
        }
    });
    menueMausScrollerAktivieren();
}

/** Scrollt das Menü bei Bewegung der Maus */
function menueMausScrollerAktivieren() {
    window.addEventListener('mousemove', menueScrollen);

    /**
     * Use Case: User dreht Mausrad oder bewegt Scrollbar oder wischt
     * Dann soll das Menü nicht mehr mit der Maus bewegt werden
     * @param {string} wheel - das zu überwachene Event
     * @param {function} killWheelListener - die Funktion erhält einen Namen, um sie beim Aufruf entfernen zu können
     */
    addEventListener('wheel', function killWheelListener() {
        // Dieser Codeblock braucht nur ein einziges Mal ausgeführt werden. Deshalb wird der Event Listener entfernt.
        removeEventListener('wheel', killWheelListener);
        kill();
    });
    addEventListener('touchstart', function killTouchListener() {
        // Dieser Codeblock braucht nur ein einziges Mal ausgeführt werden. Deshalb wird der Event Listener entfernt.
        removeEventListener('touchstart', killTouchListener);
        kill();
    });

    function kill() {
        // Die Seite nicht mehr bei Mausbewegung scrollen
        window.removeEventListener('mousemove', menueScrollen);
        // Bei Scrollen per Touch/Scrollbar/Mouserad/Pfeiltasten (also nicht Maus) sollen die Menüpunkte einrasten
        // Das passiert über CSS scrollsnap, das über das data Attribut data-wheel-scroll gesteuert wird
        document.body.setAttribute('data-wheel-scroll', 'true');
    }
}

/** Bei jeder Bewegung der Maus die Scrollposition neu berechnen */
function menueScrollen(e) {
    // Prozentualer Abstand vom oberen/unteren Viewportrand, ab dem das Scrollen gerechnet werden soll
    let randabstandProzent = 0.2;
    let faktor = 1 / (1 - (2 * randabstandProzent));

    /**
     * Nimmt eine Zahl und verändert sie so, dass sie sich innerhalb gegebener Grenzen bewegt
     * @constructor
     * @param {integer} number - die zu verändernde Zahl
     * @param {function} min - Die minimale Grösse der auszugebenden Zahl
     * @param {function} min - Die maximale Grösse der auszugebenden Zahl
     */
    function bound(number, min, max) {
        return Math.max(Math.min(number, max), min);
    }

    // mouseYProzent rechnet die vertikale Mausposition in Prozent um (relativ zum Viewport, Dezimalschreibweise)
    let mouseYProzent = e.clientY / window.innerHeight;
    // Die Höhe des scrollbaren Elements
    let scrollhoehe = menueDiv.scrollHeight - window.innerHeight;
    let scrollzielRoh = (mouseYProzent - randabstandProzent) * faktor * scrollhoehe;
    // Das Scrollziel ist Zielposition des Scrollbalkens
    let scrollziel = bound(scrollzielRoh, 0, scrollhoehe);

    gsap.to(menue, {
        scrollTo: {
            y: scrollziel,
            // Scrollen soll die Funktion nicht deaktivieren,
            // dazu zählt nämlich auch das Scrollen per event mousemove,
            // und damit würde menueScrollen() sich selbst deaktivieren
            autoKill: false,
            ease: 'linear',
            snap: 0.25
        },
        duration: 0
    });
}

// Der Zähler wird zum Deaktivieren der Scrolltrigger beim Schliessen des Menüs genutzt
var menueScrolltriggerZaehler;

/**
 * Event listener auf das Event eventMenuestatus
 * Ruft die Scrolltrigger auf und killt sie
 * @constructor
 * @param {string} eventMenuestatus - Event, das bei Klick auf den Hamburger ausgelöst wird
 */
document.addEventListener('eventMenuestatus', function (event) {
    // Menü aktiv
    if (event.detail.menueStatus == true) {
        menueScrolltriggerZaehler = 0;
        menueScrolltriggerAktivieren();
        scrolltriggerRefresh();
    // Menü inaktiv
    } else {
        window.removeEventListener('mousemove', menueScrollen);
        document.body.setAttribute('data-wheel-scroll', 'false');
        menueScrolltriggerDeaktivieren();
        scrolltriggerRefresh();
    }
});

/** Setzt auf den body einen event listener, der bei Abschluss von CSS transitions feuert.
 * Nach Abschluss jeder transition werden alle ScrollTrigger aktualisiert.
 * Das ist nötig, weil der body bei aktivem Menü position fixed ist.
 */
function scrolltriggerRefresh() {
    document.body.addEventListener('transitionend', (event) => {
        // gsap.delayedCall(0.4, ScrollTrigger.refresh());
        ScrollTrigger.refresh();
    });
}

/** Deaktiviert alle Scrolltrigger des Menüs
 */
function menueScrolltriggerDeaktivieren() {
    // Die Scrolltrigger müssen rückwärts reverted werden
    // Der verkleinern-Scrolltrigger merkt sich die CSS-Werte des vergrössern-Scrolltriggers
    // Wird also zuerst der vergrössern-Scrolltrigger reverted, stellt das revert
    // auf den verkleinern-Scrolltrigger das CSS auf die Werte des vergrösserten Zustandes
    for (i=menueScrolltriggerZaehler; i>0; i--) {
        var target = gsap.getById('menueScrolltrigger--' + i)._targets[0];
        gsap.getById('menueScrolltrigger--' + i).revert();
    }
    menueScrolltriggerZaehler = 0;
}

window.addEventListener('eventLayoutaenderung', function() {
    if (menueObjekt.menueAktiv === true) {
        menueScrolltriggerDeaktivieren();
        menueScrolltriggerAktivieren();
    }
});

/** Dem per Scrollen zentrierten Menüpukt eon data Attribut geben, das CSS Styling ermöglicht
 */
function menueMenuepunktScrollAktivieren(element, fromTo, scale, progress) {
    // Das erste Element
    if (fromTo == 'from') {
        if (progress < 0.5) {
            element.setAttribute('data-aktiv', 'true');
        } else {
            element.setAttribute('data-aktiv', 'false');
        }
    // Alle übrigen Elemente
    } else {
        if (scale > 1) {
            if (progress > 0.5) {
                element.setAttribute('data-aktiv', 'true');
            } else {
                element.setAttribute('data-aktiv', 'false');
            }
        } else {
            if (progress < 0.5) {
                element.setAttribute('data-aktiv', 'true');
            } else {
                element.setAttribute('data-aktiv', 'false');
            }
        }
    }
}